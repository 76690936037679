import { createTheme } from "@mui/material";
import { grey, red } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: red[700],
    },
    secondary: {
      main: grey[500],
    },
    background: {
      default: grey[50],
    },
  },
  typography: {
    h4: {
      fontWeight: "bold",
    },
    body1: {
      fontWeight: "medium",
    },
  },
});
