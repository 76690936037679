import { Box, styled } from "@mui/material";
import { red } from "@mui/material/colors";

export const EngageOSUText = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px",
  "& .engage": {
    color: "white",
    fontWeight: "bold",
    fontSize: "7rem",
    textShadow: "2px 2px 4px grey",
    "@media (max-width: 625px)": {
      fontSize: "6rem",
    },
    "@media (max-width: 550px)": {
      fontSize: "4rem",
    },
  },
  "& .osu": {
    color: red[700],
    fontWeight: "bold",
    fontSize: "7rem",
    textShadow:
      "1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white",
    marginLeft: "10px",
    "@media (max-width: 625px)": {
      fontSize: "6rem",
    },
    "@media (max-width: 550px)": {
      fontSize: "4rem",
      marginLeft: "5px",
    },
  },
});
