import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  ThemeProvider,
  TextField,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSpring, animated } from "@react-spring/web";
import EmailIcon from "@mui/icons-material/Email";

import { BackgroundBox, AnimatedBox, LogoBox, EngageOSUText, theme } from ".";

export const SignupForm: React.FunctionComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9]+.[0-9]+@([a-zA-Z0-9.-]+\.)?osu\.edu$/;
    return regex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    if (!validateEmail(email)) {
      setError("Oops! Please enter a valid osu.edu email address.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess(true);
        setEmail("");
      } else if (response.status === 409) {
        setError("Looks like you've already signed up!");
      } else {
        setError("Uh-oh! Sign up failed. Please try again later.");
      }
    } catch (err) {
      setError("Yikes! An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const { transform, opacity } = useSpring({
    opacity: success ? 1 : 0,
    transform: `perspective(600px) rotateY(${success ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  return (
    <ThemeProvider theme={theme}>
      <BackgroundBox>
        <Container maxWidth="sm">
          <Link to="/">
            <LogoBox>
              <img src="/logo.png" alt="EngageOSU Logo" />
            </LogoBox>
          </Link>
          <EngageOSUText>
            <span className="engage">Engage</span>
            <span className="osu">OSU</span>
          </EngageOSUText>
          <Box
            display="flex"
            minHeight="30vh"
            marginLeft="-25px"
            marginRight="-25px"
          >
            <Box position="relative" width="100%" height="400px">
              <animated.div
                style={{
                  opacity: opacity.to((o) => 1 - o),
                  transform,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backfaceVisibility: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <AnimatedBox bgcolor="white" boxShadow={3} p={3}>
                  <Typography variant="h5" gutterBottom color="primary">
                    Sign up to get notified when we're live.
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EmailIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        label="OSU Email"
                        placeholder="Enter your OSU email address"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!error}
                        helperText={error}
                        InputLabelProps={{
                          style: { color: grey[800] },
                        }}
                        required
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={3}
                    >
                      {loading ? (
                        <CircularProgress color="primary" />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            fontWeight: "bold",
                            padding: "10px 20px",
                          }}
                        >
                          Let’s Go!
                        </Button>
                      )}
                    </Box>
                  </form>
                </AnimatedBox>
              </animated.div>
              <animated.div
                style={{
                  opacity,
                  transform,
                  rotateY: "180deg",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backfaceVisibility: "hidden",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <AnimatedBox
                  bgcolor="white"
                  boxShadow={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={3}
                >
                  <Typography variant="h4" color="primary" gutterBottom>
                    Thank You!
                  </Typography>
                  <Typography variant="body1" color="primary" align="center">
                    Thanks for joining us! 🎉 Check your email to confirm your
                    address. Your confirmation code is ready and waiting but it
                    will expire in 48 hours, so don't miss out!
                  </Typography>
                </AnimatedBox>
              </animated.div>
            </Box>
          </Box>
        </Container>
      </BackgroundBox>
    </ThemeProvider>
  );
};
