import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  ThemeProvider,
} from "@mui/material";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

import { BackgroundBox, AnimatedBox, LogoBox, EngageOSUText, theme } from ".";

export const VerifyEmail: React.FunctionComponent = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const location = useLocation();
  const { width, height } = useWindowSize();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const code = queryParams.get("code");

    if (!email || !code) {
      setStatus(
        "Oops! It looks like the email or verification code is missing."
      );
      setLoading(false);
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(
          `/api/verify?email=${encodeURIComponent(
            email
          )}&code=${encodeURIComponent(code)}`
        );
        if (response.ok) {
          setStatus(
            "Yay! Your email is verified. 🎉 We'll notify you when we're live."
          );
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 15000);
        } else {
          setStatus("Uh-oh! This verification link is invalid or has expired.");
        }
      } catch (err) {
        setStatus(
          "Whoops! Something went wrong during verification. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [location.search]);

  const getTitle = () => {
    if (loading) {
      return "Verifying Your Email...";
    }
    if (status?.includes("Yay! Your email is verified")) {
      return "You're Verified! 🎉";
    }
    return "Let's Get You Verified!";
  };

  return (
    <ThemeProvider theme={theme}>
      <BackgroundBox>
        {showConfetti && <Confetti width={width} height={height} />}
        <Container maxWidth="sm">
          <Link to="/">
            <LogoBox>
              <img src="/logo.png" alt="EngageOSU Logo" />
            </LogoBox>
          </Link>
          <EngageOSUText>
            <span className="engage">Engage</span>
            <span className="osu">OSU</span>
          </EngageOSUText>
          <AnimatedBox mt={5} p={4} bgcolor="white" boxShadow={3}>
            <Typography
              variant="h4"
              gutterBottom
              color="primary"
              align="center"
            >
              {getTitle()}
            </Typography>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={3}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Typography variant="body1" color="primary" align="center">
                {status}
              </Typography>
            )}
          </AnimatedBox>
        </Container>
      </BackgroundBox>
    </ThemeProvider>
  );
};
