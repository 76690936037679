import { Box, styled } from "@mui/material";

export const LogoBox = styled(Box)({
  position: "absolute",
  top: "10px",
  left: "10px",
  "& img": {
    width: "50px",
    height: "auto",
    "@media (max-width: 550px)": {
      width: "50px",
    },
  },
});
